<template>
  <el-card>
    <div slot="header" class="card-header">
      <h3 style="margin: 0;">WEB TIMEKEEP APP REGISTRATION</h3>
      <el-select v-model="status" placeholder="Select Registration Status">
        <el-option v-for="status in statuses" :key="status.value" :value="status.value" :label="status.name"></el-option>
      </el-select>
    </div>
    <div class="controls">
      <div class="control">
        <p>Items per page: </p>
        <el-select v-model="items">
          <el-option :label="5" :value="5"></el-option>
          <el-option :label="10" :value="10"></el-option>
          <el-option :label="25" :value="25"></el-option>
          <el-option :label="50" :value="50"></el-option>
          <el-option :label="100" :value="100"></el-option>
        </el-select>
      </div>
      <div class="control">
          <el-button-group>
            <el-button type="primary" icon="el-icon-arrow-left" @click="prevPage()" :disabled="!hasPreviousPage">Previous Page</el-button>
            <el-button type="primary" @click="nextPage()" :disabled="!hasNextPage">Next Page<i class="el-icon-arrow-right el-icon-right"></i></el-button>
          </el-button-group>
      </div>
    </div>
    <el-collapse accordion class="hidden-md-and-up">
      <el-collapse-item v-for="(file, index) in data" :key="index" :name="file.id">
        <template slot="title">
          <p style="width: 100%">{{ file.computer_name }} - {{ formatDate(file.date_added) }} <el-tag :type="getStatusType(file.a_id_name)">{{ file.a_id_name }}</el-tag></p>
        </template>
        <p>Location: <b>{{ file.branch_name }}</b></p>
        <p>Requested By: <b>{{ file.request_by_em_id_name }}</b></p>
        <div class="collapse-buttons">
          <el-tooltip class="item" effect="dark" content="Approve" placement="top-start">
            <el-button size="mini" type="success" @click="confirmApprove(file, 'Holiday Approved')"><i class="el-icon-check"></i></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="Disapprove" placement="top-start">
            <el-button size="mini" type="danger" @click="confirmDisapprove(file, 'Holiday Disapproved')"><i class="el-icon-close"></i></el-button>
          </el-tooltip>
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-table :data="data" border class="hidden-sm-and-down">
      <el-table-column label="BRANCH NAME" prop="branch_name"></el-table-column>
      <el-table-column label="COMPUTER NAME" prop="computer_name"></el-table-column>
      <el-table-column label="REQUESTED BY" prop="request_by_em_id_name"></el-table-column>
      <el-table-column label="DATE REQUESTED" prop="date_added">
        <template slot-scope="props">
          {{ formatDate(props.row.date_added) }}
        </template>
      </el-table-column>
      <el-table-column label="STATUS" prop="status" min-width="150">
        <template slot-scope="props">
          <el-tag :type="getStatusType(props.row.a_id_name)">{{ props.row.a_id_name }}</el-tag>
        </template>
      </el-table-column>
        <el-table-column label="ACTIONS" fixed="right" width="170">
          <template slot-scope="scope">
            <div class="fixed-row" v-if="scope.row.a_id_name === 'Waiting'">
              <el-tooltip class="item" effect="dark" content="Approve" placement="top-start">
                <el-button size="mini" type="success" @click="confirmApprove(scope.row, 'Registration Approved')"><i class="el-icon-check"></i></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Disapprove" placement="top-start">
                <el-button size="mini" type="danger" @click="confirmDisapprove(scope.row, 'Registration Disapproved')"><i class="el-icon-close"></i></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import { format } from 'date-fns'
export default {
  data () {
    return {
      data: [],
      status: 1,
      items: 5,
      page: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      statuses: [
        {
          value: 0,
          name: 'All'
        },
        {
          value: 1,
          name: 'Waiting'
        },
        {
          value: 2,
          name: 'Approved'
        },
        {
          value: 3,
          name: 'Disapproved'
        }
      ]
    }
  },
  watch: {
    status () {
      this.getTimekeepList()
    },
    items () {
      this.getTimekeepList()
    }
  },
  mounted () {
    this.getTimekeepList()
  },
  methods: {
    getTimekeepList () {
      this.$http
        .get(`timekeeper.app/registration-list/${this.status}/${this.items}?page=${this.page}`)
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body.data : []
          this.hasNextPage = res.data.StatusCode ? !!res.data.body.next_page_url : false
          this.hasPreviousPage = res.data.StatusCode ? !!res.data.body.prev_page_url : false
        })
    },
    nextPage () {
      this.page++
      this.getTimekeepList()
    },
    prevPage () {
      this.page--
      this.getTimekeepList()
    },
    confirmApprove (file, snackbarResponse) {
      this.$confirm('Approve this request?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'info'
      })
        .then(() => {
          this.update(file, snackbarResponse, 2)
        })
    },
    confirmDisapprove (file, snackbarResponse) {
      this.$confirm('Disapprove this request?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          this.update(file, snackbarResponse, 3)
        })
    },
    update (file, snackbarResponse, status) {
      const form = {
        id: file.id,
        approved_by_em_id: this.$store.getters.user.employeeId,
        approve_a_id: status
      }
      this.$http
        .post('timekeeper.app/registration-approval', form)
        .then(res => {
          this.$message({
            type: 'success',
            message: snackbarResponse
          })
          this.getTimekeepList()
        })
    },
    formatDate (date, form = 'iii, dd MMM yyyy') {
      if (date) {
        return format(new Date(date), form)
      } else {
        return ''
      }
    },
    getStatusType (type) {
      if (type === 'Waiting') {
        return 'primary'
      } else if (type === 'Approved') {
        return 'success'
      } else {
        return 'danger'
      }
    }
  }
}
</script>
